<template>
  <div>
    <!-- <div>
      <p>{{ rowData }}</p>
    </div> -->
    <!-- {{ this.rows }} -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="row">
            <div class="col-6 col-md-4">
              <b-button class="shadow" variant="danger" @click="$router.push({ name: 'outputwassadu' })"> กลับ </b-button>
              <b-button class="shadow ml-1" variant="danger" @click="exportpdf"> พิมพ์รายการเบิกวัสดุ </b-button>
            </div>

            <div class="col-3 col-md-4" style="text-align: center">
              <p class="h2" style="color: #558cef">รายการเบิกวัสดุ</p>
            </div>
            <div class="col-3 col-md-4" style="text-align: end">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-button variant="outline-primary"><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50" /></b-button>
                </template>

                <b-dropdown-item @click="exportExcel">
                  <span>
                    <feather-icon icon="FileIcon" class="mr-50" />
                    Excel
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <hr />

          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน :</div>
            <div class="ml-1 h4">{{ this.rowData.material_code }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">
              {{ this.rowData.req_date_th }}
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">กลุ่มงานผู้ขอเบิก/ผู้ขอเบิก :</div>
            <div class="ml-1 h4">
              {{ this.rowData.work_gname }}
            </div>
            <div class="ml-1 h4 text-primary">ผู้เบิก/ผู้บังคับบัญชา :</div>
            <div class="ml-1 h4">{{ this.rowData.req_name }}</div>
          </div>
          <hr />
          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <span v-else-if="props.column.field === 'จำนวน'">
                  <div class="text-right">{{ props.row.จำนวน }}</div>
                </span>
                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
  import { BTabs, BTab } from 'bootstrap-vue';
  import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
  import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import { BButton } from 'bootstrap-vue';
  import { BFormFile } from 'bootstrap-vue';
  import { BFormSelect } from 'bootstrap-vue';
  import { BFormDatepicker } from 'bootstrap-vue';

  import { VueGoodTable } from 'vue-good-table';
  import { data } from 'vue-echarts';

  import pdfMake from 'pdfmake';
  import pdfFonts from '../font/custom-fonts';
  import axios from 'axios';
  import API from '@/views/connectDB/condb.js';
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  import _ from 'lodash';
  import dayjs from 'dayjs';
  import buddhistEra from 'dayjs/plugin/buddhistEra';
  import { numeric } from 'vee-validate/dist/rules';
  dayjs.extend(buddhistEra);
  export default {
    props: {
      rowData: Object,
    },
    components: {
      VueGoodTable,
      BFormDatepicker,
      BFormSelect,
      BFormFile,
      BRow,
      BCol,
      BButton,
      BTable,
      BCardBody,
      vSelect,
      BFormRadio,
      BFormCheckbox,
      BFormRadioGroup,
      BFormInput,
      BFormGroup,
      BRow,
      BCol,
      BTabs,
      BTab,
    },
    data: () => ({
      id: '',
      fiscal_year: '',
      name: '',
      detall: '',
      quantity: '',
      unit: '',
      per_unit: '',
      institution: '',
      registration: '',
      date_input: '',
      provided: '',
      money_type: '',
      voucher_number: '',
      selected: '',
      seller: '',
    }),
    data() {
      return {
        show: false,
        material_code: '',
        pageLength: 5,
        dir: false,
        selected: null,
        options: [
          { value: null, text: 'เลือกประเภทหลักฐาน' },
          { value: 'a', text: 'ใบส่งของ/ใบกำกับาษี' },
          { value: 'b', text: 'ใบส่งของ/ใบกำกับาษี' },
          { value: 'c', text: 'ใบส่งของ/ใบกำกับาษี' },
          { value: 'd', text: 'ใบส่งของ/ใบกำกับาษี' },
          { value: 'e', text: 'ใบส่งของ/ใบกำกับาษี' },
          { value: 'f', text: 'ใบส่งของ/ใบกำกับาษี' },

          // { value: { C: '3PO' }, text: 'This is an option with object value' },
          // { value: 'd', text: 'This one is disabled', disabled: true },
        ],
        columns: [
          {
            label: 'รหัสวัสดุ',
            field: 'm_id',
          },
          {
            label: 'รายการ',
            field: 'm_name',
            thClass: 'text-center',
          },
          {
            label: 'หน่วยนับ',
            field: 'unittype',
          },
          {
            label: 'ราคาต่อหน่วย',
            field: 'unitprice_vat2',
            type: 'number',
            formatFn: this.formatFn,
          },
          // {
          //   label: 'ราคาต่อหน่วย + vat 7%',
          //   field: 'unitprice_vat',
          //   type: 'number',
          // },
          {
            label: 'จำนวน',
            field: 'req_amount',
          },
        ],
        rows: [],
        total: '',
        searchTerm: '',
        month: ['', 'มกราคม', 'กุมภาพันธ์ ', 'มีนาคม ', 'เมษายน ', 'พฤษภาคม ', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        user_fullname: '',
        status_user: '',
        date_1: '',
        date_2: '',
        date_3: '',
      };
    },
    computed: {
      example: {
        cache: false,
        get: function () {
          return Date.now() + this.msg;
        },
      },
    },
    async created() {
      // this.checkLogout();
      _.isEmpty(this.rowData) ? this.$router.push({ path: '/pick-up-material' }) : true;

      if (this.rowData.date_1 || this.rowData.date_2 || this.rowData.date_3) {
        console.log(this.rowData.date_1);
        const date1 = this.rowData.date_1;
        this.date_1 = `${parseInt(date1.split('-')[2])} ${this.month[parseInt(date1.split('-')[1])]} ${Number(date1.split('-')[0]) + 543}`;
        this.date_2 = `${parseInt(this.rowData.date_2.split('-')[2])} ${this.month[parseInt(this.rowData.date_2.split('-')[1])]} ${Number(this.rowData.date_2.split('-')[0]) + 543}`;
        this.date_3 = `${parseInt(this.rowData.date_3.split('-')[2])} ${this.month[parseInt(this.rowData.date_3.split('-')[1])]} ${Number(this.rowData.date_3.split('-')[0]) + 543}`;

        // this.rowData.map((el) => {
        //   return {
        //     ...el,
        //     date_1: `${parseInt(el.date_1.split('-')[2])} ${this.month[parseInt(el.date_1.split('-')[1])]} ${Number(el.date_1.split('-')[0]) + 543}`,
        //     date_2: `${parseInt(el.date_2.split('-')[2])} ${this.month[parseInt(el.date_2.split('-')[1])]} ${Number(el.date_2.split('-')[0]) + 543}`,
        //     date_3: `${parseInt(el.date_3.split('-')[2])} ${this.month[parseInt(el.date_3.split('-')[1])]} ${Number(el.date_3.split('-')[0]) + 543}`,
        //   };
        // });
      }
      this.getdatatable();
      const { dep_id, usertype, username, fullname } = await JSON.parse(localStorage.getItem('username_type'));
      this.status_user = await usertype;
      const url = `${API}registerWithdraw?fullname=${this.rowData.requistion_name}`;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.data[0].dep_id);

      if (this.status_user == 'ผู้ใช้ทั่วไป') {
        this.user_fullname = await this.rowData.requistion_name;
        this.status_user = res.data.message.data[0].dep_id;
      } else {
        this.user_fullname = await this.rowData.requistion_name;
        this.status_user = await res.data.message.data[0].dep_id;
      }
    },
    methods: {
      async checkLogout() {
        console.log('ตรวจสอบออกจากระบบ');
        const { username } = await JSON.parse(localStorage.getItem('username_type'));
        console.log(username);
        axios
          .get('https://eoffice.niets.or.th/verify/auth', {
            headers: {
              'px-auth-token': `${username}`,
            },
          })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              console.log(' User อยู่ในระบบ');
            }
          })
          .catch((res) => {
            const { response } = res;
            console.log(response);
            if (response.status != 200) {
              console.log(' User ออกจากระบบเเล้ว');
              this.$router.push({ name: 'logoutUser' });
            }
          });
        console.log('-------------------------');
      },
      formatFn: function (value) {
        return Number(value)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      },
      exportExcel() {
        saveExcel({
          data: this.rows,
          fileName: `รายการเบิกวัสดุ ${dayjs(new Date()).format('DD-MM-BBBB-HHmm')}`,
          columns: [
            {
              title: 'รายการเบิกวัสดุ',
              headerCellOptions: { textAlign: 'center' },
              children: [
                {
                  title: `เลขทะเบียน: ${this.rowData.material_code}`,
                  headerCellOptions: { textAlign: '' },
                  children: [
                    {
                      title: `กลุ่มงานผู้ขอเบิก/ผู้เบิก: ${this.rowData.work_gname}`,
                      headerCellOptions: { textAlign: '' },
                      children: [
                        {
                          title: `วันที่: ${this.rowData.req_date_th}`,
                          headerCellOptions: { textAlign: '' },
                          children: [
                            {
                              title: 'รหัสวัสดุ',
                              field: 'm_id',
                            },
                            {
                              title: 'รายการ',
                              field: 'm_name',
                            },
                            {
                              title: 'หน่วยนับ',
                              field: 'unittype',
                            },
                            {
                              title: 'ราคาต่อหน่วย',
                              field: 'unitprice_ex',
                            },
                            // {
                            //   title: 'ราคาต่อหน่วย + vat 7%',
                            //   field: 'unitprice_vat',
                            //   type: 'number',
                            // },
                            {
                              title: 'จำนวน',
                              headerCellOptions: { textAlign: 'center' },
                              colSpan: 2,
                              children: [
                                { title: 'เบิก', field: 'req_amount', type: 'number' },
                                { title: 'จ่าย', field: 'req_amount', type: 'number' },
                              ],
                            },
                          ],
                        },
                        {},
                      ],
                    },
                    {},
                  ],
                },
                {},
              ],
            },
          ],
        });
      },
      async getdatatable() {
        this.show = true;
        let { material_code } = this.rowData;
        this.material_code = material_code;
        const { access_token } = await this.access_token();
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const url = `${API}materialListHistory?material_code=${material_code}`;
        const res = await axios.get(url, head);
        console.log(res);
        this.total = res.data.message.total;
        const total = Math.ceil(Number(res.data.message.total) / 100);
        console.log(total);
        const urls = Array.from({ length: total }, (_, i) => `${url}&_page=${i + 1}&_limit=100`);
        console.log(urls);
        const responses = await Promise.all(urls.map((url) => axios.get(url, head)));
        console.log(responses);
        const res_data_ = responses.flatMap((response) => response.data.message.data);
        console.log(res_data_);
        this.rows = res_data_.map((el) => {
          return {
            ...el,
            unitprice_ex: Number(el.unitprice_vat2),
            m_name: el.m_name.split('-')[1],
          };
        });
        this.show = false;
      },
      async access_token() {
        return (
          await axios.post(`${API}permit`, '', {
            headers: {
              Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
            },
          })
        ).data.message;
      },
      exportpdf() {
        console.log(dayjs(new Date()).format('DD-MM-BBBB HH:mm:ss'));

        pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
        pdfMake.fonts = {
          // download default Roboto font from cdnjs.com
          Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
          },
          // Kanit Font
          Kanit: {
            // 3. set Kanit font
            normal: 'Sarabun-Medium.ttf',
            bold: 'Sarabun-Regular.ttf',
            italics: 'Sarabun-Italic.ttf',
            bolditalics: 'Sarabun-MediumItalic.ttf',
          },
        };
        const docDefinition = {
          pageMargins: [10, 160, 10, 150],

          pageSize: 'A4',
          pageOrientation: 'landscape',
          info: {
            title: 'ใบเบิกวัสดุ',
          },
          header: [
            { text: 'ใบเบิกวัสดุ', style: 'header', margin: [0, 20, 0, 0] },
            {
              text: 'สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)',
              style: 'header',
            },
            {
              text: `เลขที่ ${this.rowData.material_code.split('-')[0]}-${this.rowData.material_code.split('-')[1]}/${this.rowData.material_code.split('-')[2]}`,
              style: 'header',
              alignment: 'right',
              margin: [0, 20, 20, 0],
            },
            { text: `กลุ่มงานผู้ขอเบิก/ผู้ขอเบิก: ${this.rowData.work_gname}`, style: 'header', alignment: 'left', margin: [20, 20, 0, 0] },
            {
              text: `เพื่อให้เป็นไปตามระเบียบกระทรวงการคลังว่าด้วยการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐ พ.ศ 2560 ข้อ 204 และ 205 ข้าพเจ้าขอเบิกพัสดุตามรายการข้างล่างนี้ เพื่อใช่ในสำนักงาน ตามรายชื่อผู้รับผิดชอบ/ผู้รับมอบหมาย ดังนี้`,
              style: 'header',
              alignment: 'left',
              margin: [80, 20, 80, 10],
            },
          ],
          footer: {
            columns: [
              {
                text: `(ลงชื่อ).............................................................................................(ผู้ขอเบิกวัสดุ/ผู้รับวัสดุ)
             ( ${this.user_fullname})

              ตำแหน่ง: ${this.status_user ? this.status_user : '...........................................................'}
              วันที่ : ${this.rowData.req_date_th ? this.rowData.req_date_th : '.......................................................'}


            (ลงชื่อ).............................................................................................(ผู้สั่งจ่าย)
             ( ${this.rowData.staff_name3 == null ? '........................................................................................' : this.rowData.staff_name3} )

              ตำแหน่ง: ${this.rowData.department3 ? this.rowData.department3 : '...........................................................'}
              วันที่ : ${this.rowData.date_3 ? this.date_3 : '.......................................................'}
              `,
                alignment: 'center',
                style: 'text',
                margin: [0, 0, 0, 10],
              },
              {
                text: `(ลงชื่อ).............................................................................................(ผู้เบิก/ผู้บังคับบัญชา)
             ( ${this.rowData.req_name == null ? '........................................................................................' : this.rowData.req_name} )

              ตำแหน่ง: ${this.rowData.dep_name ? this.rowData.dep_name : '...........................................................'}
              วันที่ : ${this.rowData.req_date_th ? this.rowData.req_date_th : '.......................................................'}


               (ลงชื่อ).............................................................................................(ผู้จ่าย)
            ( ${this.rowData.staff_name1 == null ? '........................................................................................' : this.rowData.staff_name1} )

             ตำแหน่ง: ${this.rowData.department1 ? this.rowData.department1 : '...........................................................'}
              วันที่ : ${this.rowData.date_1 ? this.date_1 : '.......................................................'}
              `,

                alignment: 'center',
                style: 'text',
                margin: [0, 0, 0, 10],
              },
            ],
          },

          content: [
            {
              style: 'tableExample',
              table: {
                headerRows: 1,
                widths: ['auto', 'auto', '*', 'auto', 'auto', 30, 30, 'auto'],
                body: [
                  [
                    { text: 'ลำดับที่', rowSpan: 2, style: 'tableHeader' },
                    { text: 'รหัสวัสดุ', rowSpan: 2, style: 'tableHeader' },
                    { text: 'รายการ สิ่งของ', rowSpan: 2, style: 'tableHeader' },
                    { text: 'หน่วยนับ', rowSpan: 2, style: 'tableHeader' },
                    { text: 'ราคาต่อหน่วย', rowSpan: 2, style: 'tableHeader' },
                    { text: 'จำนวน', colSpan: 2, style: 'tableHeader' },
                    { text: '', style: 'tableHeader' },
                    { text: 'หมายเหตุ', rowSpan: 2, style: 'tableHeader' },
                  ],
                  [
                    { text: '', style: 'tableHeader', alignment: 'center' },
                    { text: '', style: 'tableHeader', alignment: 'center' },
                    { text: '', style: 'tableHeader', alignment: 'center' },
                    { text: '', style: 'tableHeader', alignment: 'center' },
                    { text: '', style: 'tableHeader', alignment: 'center' },
                    { text: 'เบิก', alignment: 'center', style: 'tableHeader', margin: [15, 0, 0, 0] },
                    { text: 'จ่าย', alignment: 'center', style: 'tableHeader', margin: [15, 0, 0, 0] },
                    { text: '', style: 'tableHeader', alignment: 'center' },
                  ],

                  ...this?.rows.map((res, index) => {
                    return [
                      { text: index + 1, style: 'text_center' },
                      { text: res.m_id, style: 'text_center' },
                      { text: res.m_name, style: 'text_left' },
                      { text: res.unittype, style: 'text_center' },
                      {
                        text: res.unitprice
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                        style: 'text_right',
                      },
                      { text: res.req_amount, style: 'text_right' },
                      { text: res.req_amount, style: 'text_right' },
                      // { text: res.unitprice, style: 'text_center' },
                      { text: '', style: 'text_center' },
                    ];
                  }),
                ],
              },
            },
          ],
          defaultStyle: {
            font: 'Kanit',
          },
          styles: {
            tableExample: {
              margin: [0, 5, 0, 0],
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: 'black',
              alignment: 'center',
            },
            header: {
              fontSize: 9,
              bold: true,
              alignment: 'center',
            },
            title: {
              bold: true,
              fontSize: 9,
              color: 'black',
              margin: [0, 15, 0, 0],
            },
            anotherStyle: {
              italics: true,
              alignment: 'center',
            },
            text_center: {
              alignment: 'center',
              fontSize: 9,
            },
            text_right: {
              alignment: 'right',
              fontSize: 9,
            },
            text_left: {
              alignment: 'left',
              fontSize: 9,
            },
            text: {
              fontSize: 9,
              margin: [0, 0, 0, 0],
            },
          },
        };
        pdfMake.createPdf(docDefinition).download(`ใบเบิกวัสดุ ${dayjs(new Date()).format('DD-MM-BBBB-HHmm')}`);
        // pdfMake.createPdf(docDefinition).open();
      },
      getfrom() {
        // console.log(id);
      },
    },
  };
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
